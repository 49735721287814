import React from 'react'
import { Link } from 'gatsby'

import './index.css'

function ResourceVideo({ children, url }) {
  const childs = React.Children.map(children, child =>
    React.cloneElement(child, { ...child.props, url })
  )

  return <div className="resource-video">{childs}</div>
}

ResourceVideo.Title = function ResourceVideoTitle({ children, url }) {
  return (
    <h4 className="resource-video-title mt-16">
      <Link to={url}>{children}</Link>
    </h4>
  )
}

ResourceVideo.Description = function ResourceVideoDescription({ children }) {
  return <div className="resource-video-description mt-8">{children}</div>
}

ResourceVideo.Footer = function ResourceVideoFooter({ children }) {
  return <footer className="mt-16">{children}</footer>
}

export default ResourceVideo
