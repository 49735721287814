import React, { useMemo, useCallback } from 'react'
import { graphql } from 'gatsby'

import Layout from '@c/layout'
import Breadcrumb from '@c/breadcrumb'

import makeSlug from '@u/make-slug'

import withLocation from '../../../withLocation'
import QuickSearch from '@c/quick-search'
import Pagination from '@c/pagination'
import quickSearchBuilder, {
  QuickSearchMapping
} from '@c/quick-search/quick-search-builder'

import usePaginator from '@/hooks/resource/use-paginator'
import classNames from 'classnames'

import get from 'lodash/get'
import map from 'lodash/map'
import ResourceVideo from '@c/resource-video'
import Video from '@c/video'
import Selectable from '@/components/selectable'
import AuthorCard from '@c/author-card'
import { Link } from 'gatsby'
import addPageNumberToPage from '../add-page-number-to-page'
import addCategoryTitleToPage from '../add-category-title-to-page'

const Page = ({ data, pageContext }) => {
  const { pageSettings } = data
  const {
    resourcesSlug,
    resourcesTitle,
    resourcesVideoSlug,
    resourcesVideoTitle,
    _allResourcesSlugLocales,
    _allResourcesVideoSlugLocales,
    categorySlug
  } = pageSettings
  const title = pageSettings.resourcesVideoTitle
  const locale = pageContext.locale

  // Prepare the paginator
  const paginator = usePaginator({
    currentPage: pageContext.currentPage,
    totalPages: pageContext.numPages
  })
  // Map videos from graphql result
  const videos = useMemo(() => data.allDatoCmsVideo.nodes)
  // Filter categories that doesnt have any videos
  const categories = data.categories.nodes.filter(o =>
    data.usedCategories.distinct.includes(o.slug)
  )
  // Check if this list is categorized
  const currentCategory =
    (pageContext?.category &&
      categories.find(o => o.slug === pageContext?.category)) ||
    null
  // Set the text of category selectable
  const categoryText = currentCategory
    ? currentCategory.title
    : data?.datoCmsTranslation?.filteringAll
  // Create breadcrumb base
  const parentPages = [
    {
      title: resourcesTitle,
      url: makeSlug.generic(pageContext.locale, resourcesSlug)
    }
  ]

  const genSlug = useCallback(
    function genSlug(slug) {
      return makeSlug.generic(
        pageContext.locale,
        resourcesSlug,
        resourcesVideoSlug,
        slug
      )
    },
    [pageContext, resourcesSlug, resourcesVideoSlug]
  )
  const paginatorSlug = genSlug(
    currentCategory ? `${categorySlug}/${currentCategory.slug}` : ''
  )
  // If this is a categorized list then add uncategorized link as a breadcrumb parent
  if (currentCategory) {
    parentPages.push({
      title: resourcesVideoTitle,
      url: genSlug('')
    })
  }

  // Prepare the search list for quick search
  const searchList = map(data.searchList.edges, 'node')
  const searchMappings = quickSearchBuilder()
    .addCriteria(
      new QuickSearchMapping(searchList, item =>
        makeSlug.generic(locale, resourcesSlug, resourcesVideoSlug, item?.slug)
      )
    )
    .build()
  const fnCreateSeoMeta = () => {
    const locales = _allResourcesSlugLocales.map(o => o.locale)
    const r = []
    const fnGetByLang = (allLocale, locale) =>
      allLocale?.find(o => o.locale === locale)?.value
    locales.forEach(element => {
      const slugChain = [
        fnGetByLang(_allResourcesSlugLocales, element),
        fnGetByLang(_allResourcesVideoSlugLocales, element)
      ]
      r.push({
        rel: 'alternate',
        href: makeSlug.generic(element, ...slugChain),
        hreflang: element
      })
    })
    return {
      link: r,
      title: addPageNumberToPage(
        addCategoryTitleToPage(title, currentCategory),
        locale,
        paginator
      )
    }
  }
  const videoThumb = video => video?.thumb || video?.localVideoThumbnail
  const fnMakeCategorizedSlug = item =>
    genSlug(item?.categories?.length ? `${categorySlug}` : '')
  return (
    <Layout
      locale={pageContext.locale}
      seo={{
        ...fnCreateSeoMeta()
      }}
    >
      {/* BREADCRUMB */}
      <div className="container mb-40">
        <div className="row align-items-center">
          <div className="col-12 col-lg-auto">
            <Breadcrumb
              className={currentCategory ? '' : 'resource-breadcrumb'}
              parentPages={parentPages}
              currentPage={currentCategory?.title || title}
              wrapCurrentPageWithH1={true}
            />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12 col-lg-4" style={{ marginBottom: '16px' }}>
            <Selectable text={categoryText} style={{ zIndex: 2 }}>
              <Link to={genSlug('')}>
                {data?.datoCmsTranslation?.filteringAll}
              </Link>
              {categories.map((o, key) => (
                <Link key={key} to={genSlug(`${categorySlug}/${o.slug}`)}>
                  {o.title}
                </Link>
              ))}
            </Selectable>
          </div>
          <div
            className={classNames(
              'col-12 d-flex flex-column align-items-center',
              paginator.result.totalPages > 1 ? 'col-lg-auto' : 'col-lg-4'
            )}
          >
            <QuickSearch
              mappings={searchMappings}
              style={{ marginBottom: '16px', zIndex: 1, minWidth: 'auto' }}
            />
            <Pagination paginator={paginator} baseSlug={paginatorSlug} />
          </div>
        </div>
        <div className="d-flex justify-content-center justify-content-lg-end"></div>
      </div>
      {/* ALL */}
      <div className="container">
        <div className="row mt-40">
          {videos.map(video => (
            <div key={video.id} className="col-12 col-lg-4 mb-60">
              <ResourceVideo
                url={makeSlug.generic(
                  video.locale,
                  resourcesSlug,
                  resourcesVideoSlug,
                  video.slug
                )}
              >
                <Video photo={videoThumb(video)} totalTime={video.duration} />
                <ResourceVideo.Title>{video.title}</ResourceVideo.Title>
                <ResourceVideo.Description>
                  <div
                    dangerouslySetInnerHTML={{ __html: video.shortDescription }}
                  ></div>
                </ResourceVideo.Description>
                <ResourceVideo.Footer>
                  <AuthorCard
                    photo={get(video, 'author.profileImage')}
                    alt={get(video, 'author.profileImage.alt') || ''}
                    name={`${get(video, 'author.firstName')} ${get(
                      video,
                      'author.lastName'
                    )}`}
                    nickname={get(video, 'author.nickname')}
                    categoryBase={fnMakeCategorizedSlug(video)}
                    categories={video.categories}
                    locale={locale}
                  />
                </ResourceVideo.Footer>
              </ResourceVideo>
            </div>
          ))}
        </div>
      </div>
      <div className="container">
        <div className="d-flex flex-column align-items-center">
          <Pagination paginator={paginator} baseSlug={paginatorSlug} />
        </div>
      </div>
    </Layout>
  )
}

export default withLocation(Page)

export const query = graphql`
  query(
    $locale: String
    $skip: Int!
    $limit: Int!
    $filter: DatoCmsVideoFilterInput!
  ) {
    datoCmsTranslation(locale: { eq: $locale }) {
      filteringAll
    }
    categories: allDatoCmsCategory(filter: { locale: { eq: $locale } }) {
      nodes {
        title
        slug
        _allSlugLocales {
          locale
          value
        }
      }
    }
    usedCategories: allDatoCmsVideo(
      filter: { locale: { eq: $locale }, slug: { regex: "/.+/" } }
    ) {
      distinct(field: categories___slug)
    }
    searchList: allDatoCmsVideo(
      filter: { locale: { eq: $locale }, slug: { regex: "/.+/" } }
    ) {
      edges {
        node {
          _allSlugLocales {
            locale
            value
          }
          title
          slug
          locale
        }
      }
    }
    allDatoCmsVideo(
      filter: $filter
      limit: $limit
      skip: $skip
      sort: { fields: [meta___createdAt], order: DESC }
    ) {
      nodes {
        _allSlugLocales {
          locale
          value
        }
        id
        locale
        slug
        title
        shortDescription
        duration
        thumb {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        categories {
          title
          slug
        }
        localVideoThumbnail {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        video {
          title
          url
          thumbnailUrl
        }
        author {
          firstName
          lastName
          nickname
          profileImage {
            alt
            asset {
              localImage {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 30)
                }
              }
            }
          }
        }
      }
    }

    pageSettings: datoCmsPageSetting(locale: { eq: $locale }) {
      _allResourcesSlugLocales {
        locale
        value
      }
      _allResourcesVideoSlugLocales {
        locale
        value
      }
      _allCategorySlugLocales {
        locale
        value
      }
      resourcesTitle
      resourcesSlug
      resourcesVideoTitle
      resourcesVideoSlug
      categorySlug
    }
  }
`
